













































































































import { Component, Vue } from "vue-property-decorator";
import { MenuButton } from "@/types/components/navbar/";

/**
 * Navbar component
 *
 * @author Kevin Danne <danne@skiba-procomputer.de>
 */
@Component
export default class Navbar extends Vue {
    private menuButtons: MenuButton[] = [
        {
            to: { name: "home", hash: "#app" },
            exact: true,
            text: this.$t("buttons.home").toString(),
        },
        /*{
            to: { name: "news" },
            exact: false,
            text: this.$t("buttons.news").toString()
        },*/
        {
            to: { name: "home", hash: "#about" },
            exact: false,
            text: this.$t("buttons.company").toString(),
        },
        {
            to: { name: "home", hash: "#skills" },
            exact: false,
            text: this.$t("buttons.skills").toString(),
        },
        {
            to: { name: "sale", hash: "#sale" },
            exact: false,
            text: this.$t("buttons.sale").toString(),
        },
        {
            to: { name: "career" },
            exact: false,
            text: this.$t("buttons.careers").toString(),
        },
        {
            to: { name: "home", hash: "#partners" },
            exact: false,
            text: this.$t("buttons.partners").toString(),
        },
        /*
            {
                to: { name: 'home', hash: '#contactPersons' },
                exact: false,
                text: this.$t('buttons.contactPersons').toString()
            },
            */
        {
            to: { name: "home", hash: "#contact" },
            exact: false,
            text: this.$t("buttons.contact").toString(),
        },
    ];

    private supportCenterUrl = "https://get.teamviewer.com/skibafernwartung";
}
